import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Container, Divider } from '@material-ui/core';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { businesses, tickets } from '../../../../state';
import { BusinessHeader, BusinessFooter, Content } from '../../../layouts';
import CheckInForm from './CheckInForm';

const TicketCheckIn = () => {
  const { i18n } = useTranslation();
  const { id, businessId } = useParams();
  const dispatch = useDispatch();
  const fetchTicket = (payload) =>
    dispatch(tickets.actions.fetchTicket(payload));
  const business = useSelector(businesses.selectors.selectBusiness);
  React.useEffect(() => {
    (async () => {
      dispatch(businesses.actions.fetchBusiness(businessId));
      const res = await fetchTicket(id);
      if (
        !res?.payload?.businessId ||
        res?.payload?.businessId !== businessId
      ) {
        navigate('/');
      }
    })();
  }, []);

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);
  const useStyles = makeStyles((theme) => ({
    fsBackground: {
      background: business?.brandColors?.backgroundColor || '#e4f9fc',
      minHeight: '170px',
      maxHeight: '170px',
      width: '100%',
      position: 'absolute',
      left: 0,
      zIndex: -1,
    },
    checkBalance: {
      position: 'absolute',
      right: '5.7%',
      top: '50%',
      width: '250px',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
      transform: 'translateY(-50%)',
      '&:hover': {
        textDecoration: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        top: 'auto',
        right: 0,
        fontSize: '13px',
      },
    },
    checkBalanceWrapper: {
      position: 'absolute',
      width: '100%',
      top: '65px',
      left: 0,
      height: '170px',
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        top: 'auto',
        height: 'auto',
        position: 'relative',
        paddingTop: '25px',
      },
    },
    textColor: {
      '& *:not(.Mui-error):not(.sq-card-message-error):not(button):not(button span)': {
        color: business.brandColors?.textColor,
      },
    },
  }));
  const classes = useStyles();
  const { logoUrl } = business;
  return (
    <Box className={classes.textColor}>
      <BusinessHeader
        logoUrl={logoUrl}
        merchantUrl=""
        merchantPintunaUrl=""
        bgcolor={business.brandColors?.headerColor || '#eee'}
      />
      <Box
        bgcolor={business.brandColors?.backgroundColor || '#eee'}
        style={{ minHeight: 'calc(100vh - 133px)' }}
      >
        <Container maxWidth="lg">
          <Content md={7} sm={10}>
            <CheckInForm />
          </Content>
        </Container>
      </Box>
      <Divider />
      <BusinessFooter bgcolor={business.brandColors?.footerColor} />
    </Box>
  );
};
export default TicketCheckIn;
