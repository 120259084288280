import React from 'react';
import { Router } from '@reach/router';
import { TicketPurchase } from '../components/routes';
import TicketCheckIn from '../components/routes/Ticket/CheckIn/TicketCheckIn';

const CardPage = () => {
  return (
    <Router basepath="tickets">
      <TicketPurchase path="events/:id" />
      <TicketCheckIn path=":businessId/events/:id/check-in" />
    </Router>
  );
};

export default CardPage;
